<template>
  <b-container class="min-vh-100 d-flex flex-column">
    <b-row>
      <b-col cols="1" />
      <b-col>
        <h1 style="margin-top: 4.25rem;">{{$store.state.bike.name}}</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="1" />
      <b-col cols="10">
        <p style="color: var(--secondary-text-icon-color)">Akkustand: {{$store.state.bike.power}}%</p>
      </b-col>
      <b-col cols="1" />
    </b-row>
    <b-row>
      <b-col cols="1" />
      <b-col cols="10">
        <h4>Fahrrad reserviert</h4>
        <p> Du kannst deine Fahrt jeder Zeit starten. Hier siehst du die verbleibende Zeit, in der das Fahrrad noch reserviert ist.</p>
      </b-col>
      <b-col cols="1" />
    </b-row>
    <b-row>
      <b-col cols="1" />
      <b-col cols="10">
        <b-progress :value="18" :max="30"/>
        <p style="color: var(--visual-information-color)"><br>12 Minuten übrig</p>
      </b-col>
      <b-col cols="1" />
    </b-row>
    <b-row>
      <b-col cols="1" />
      <b-col cols="10">
        <img src="@/assets/illustrations/pause.svg" class="illustration" alt="biker"
          style="width: 100%; height: 100%; margin-bottom: 3.5rem;"/>
      </b-col>
      <b-col cols="1" />
    </b-row>
    <b-row style="text-align: center;" class="flex-grow-1">
      <b-col cols="1" />
      <b-col cols="10" class="justify-content-center align-self-center">
        <b-button variant="secondary" class="buttonSpacing" @click="cancelBike">Abbrechen</b-button>
        <b-button variant="primary" class="buttonSpacing" @click="unlockBike">Entsperren</b-button>
      </b-col>
      <b-col cols="1" />
    </b-row>
  </b-container>
</template>

<script>
  export default {
    name: 'BikeUnlock',
    data() {
      return {
      }
    },
    components: {},
    methods: {
      cancelBike() {
        this.$router.push('/bikeinfo');
      },
      unlockBike() {
        this.$router.push('/biketutorial/1');
      }
    },

    mounted() {
      // checks if the page loaded without any bike data
      if (Object.keys(this.$store.state.bike).length === 0) {
        this.$router.push('/').then(() => this.toast('Es ist ein Fehler aufgetreten', 'Bitte versuche es erneut',
          'danger'));
      }
    }
  }
</script>

<style scoped>
</style>